import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor() { }

  getDeliveryTypes(): { value: string, label: string }[] {
    return [
      { value: 'EMail', label: 'EMail' },
      { value: 'Regular Mail-USPS', label: 'Regular Mail-USPS' },
      { value: '2 Day', label: '2 Day' },
      { value: '3 Day', label: '3 Day' },
      { value: 'Standard Overnight', label: 'Standard Overnight' },
      { value: 'Priority Overnight', label: 'Priority Overnight' }
    ];
  }
  
  educationVerifyTypes(): { value: string, label: string }[] {
    return [
      { value: 'Objective Evidence in file', label: 'Objective Evidence in file' },
      { value: 'By University', label: 'By University' }
    ];
  }
}

    