import { Component } from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'dcar-employee',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './employee.component.html',
  styleUrl: './employee.component.css'
})
export class EmployeeComponent {

}
