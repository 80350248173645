<sl-tab-group>
    <sl-tab slot="nav" panel="main">Main Detail</sl-tab>
    <sl-tab slot="nav" panel="checklist">Checklist</sl-tab>
    <sl-tab slot="nav" panel="com-log">Com Log</sl-tab>
    
    <sl-tab-panel name="main">
        <dcar-candidate-detail #candidateDetailComponent></dcar-candidate-detail>
    </sl-tab-panel>
    <sl-tab-panel name="checklist">
        <dcar-candidate-checklist #candidateChecklistComponent></dcar-candidate-checklist>
    </sl-tab-panel>
    <sl-tab-panel name="com-log">
        <dcar-candidate-com-log #candidateComLogComponent></dcar-candidate-com-log>
    </sl-tab-panel>
  </sl-tab-group>