import { Component, CUSTOM_ELEMENTS_SCHEMA, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateService } from './candidate.service';
import { CommonModule } from '@angular/common';
import { CandidateDetailComponent } from './candidate-detail.component';
import { CandidateChecklistComponent } from "./candidate-checklist.component";

@Component({
  selector: 'dcar-candidate-detail-tabs',
  standalone: true,
  imports: [CommonModule, CandidateDetailComponent, CandidateChecklistComponent],
  templateUrl: './candidate-detail-tabs.component.html',
  styleUrl: './candidate-detail-tabs.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateDetailTabsComponent extends AppComponentBase {
  @ViewChild('candidateDetailComponent') candidateDetailComponent:CandidateDetailComponent;

  //controlsDisabled = true;

  constructor(
    injector: Injector,
    public _candidateService: CandidateService
  ) {
    super(injector);
  }

  toggleDiabled() {
    //this.controlsDisabled = !this.controlsDisabled
    this.candidateDetailComponent.toggleDiabled();
  }

  submitForm() {
    //this.candidateDetailForm.nativeElement.submit();
    this.candidateDetailComponent.submitForm();
  }

}
