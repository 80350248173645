<div [ngSwitch]="current_route" class="col-12 flex">
    <template-menu *ngSwitchCase="'/app/template'"></template-menu>
    <billing-menu *ngSwitchCase="'/app/billing'"></billing-menu>
    <communications-menu *ngSwitchCase="'/app/communications'"></communications-menu>
    <companies-menu *ngSwitchCase="'/app/companies'"></companies-menu>
    <programs-menu *ngSwitchCase="'/app/programs'"></programs-menu>
    <contracts-menu *ngSwitchCase="'/app/contracts'"></contracts-menu>
    <documents-menu *ngSwitchCase="'/app/documents'"></documents-menu>
    <lookups-menu *ngSwitchCase="'/app/lookups'"></lookups-menu>
    <payroll-menu *ngSwitchCase="'/app/payroll'"></payroll-menu>
    <candidates-menu *ngSwitchCase="'/app/candidates'"></candidates-menu>
    <technical-specialists-menu *ngSwitchCase="'/app/technical-specialists'"></technical-specialists-menu>
    <reports-menu *ngSwitchCase="'/app/reports'"></reports-menu>
    <timecards-menu *ngSwitchCase="'/app/timecards'"></timecards-menu>
    <wc-maintenance-menu *ngSwitchCase="'/app/wc-maintenance'"></wc-maintenance-menu>
</div>
