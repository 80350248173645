import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'dcar-client',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './client.component.html',
  styleUrl: './client.component.css'
})
export class ClientComponent {

}
