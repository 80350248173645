import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
//import { HeaderModule } from '@app/header/header.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { IMaskModule } from 'angular-imask';
import { FileUploadModule } from 'ng2-file-upload';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PerfectScrollbarModule } from '@craftsjs/perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { AppCommonModule } from './common/app-common.module';
import { ThemesLayoutBaseComponent } from './layout/themes/themes-layout-base.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { 
    DxButtonModule, 
    DxChartModule, 
    DxCheckBoxModule,
    DxDataGridModule, 
    DxMapModule, 
    DxPieChartModule, 
    DxPopoverModule, 
    DxPopupModule, 
    DxTemplateModule, 
    DxVectorMapModule, 
    DxFileManagerModule,
    DxListModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxSelectBoxModule,
    DxFilterBuilderModule,
    DxSpeedDialActionModule,
} from 'devextreme-angular';
import { ListboxModule } from 'primeng/listbox';
import { SidebarModule } from 'primeng/sidebar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxDataTableModule } from 'jqwidgets-ng/jqxdatatable';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { EmtAddressDisplayComponent } from '@app/_components/emt-address-display/emt-address-display.component';
import { EmtContactDisplayComponent } from '@app/_components/emt-contact-display/emt-contact-display.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EmtDialogComponent } from '@app/_components/emt-dialog/emt-dialog.component';
import { GoogleMapsModule} from '@angular/google-maps'

import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';




const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModalModule,
    //HeaderModule,
    TabsModule,
    TooltipModule,
    BsDropdownModule,
    PopoverModule,
    BsDatepickerModule,
    AppCommonModule,
    FileUploadModule,
    AppRoutingModule,
    UtilsModule,
    ServiceProxyModule,
    TableModule,
    PaginatorModule,
    ProgressBarModule,
    PerfectScrollbarModule ,
    IMaskModule,
    ImageCropperModule,
    AutoCompleteModule,
    NgxSpinnerModule,
    AppBsModalModule,
    FontAwesomeModule,
    NgSelectModule,
    DxDataGridModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxPopoverModule,
    DxTemplateModule,
    DxMapModule,
    DxVectorMapModule,
    DxPieChartModule,
    DxChartModule,
    DxPopupModule,
    DxFileManagerModule,
    DxListModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxSelectBoxModule,
    ListboxModule,
    PdfViewerModule,
    NgxDocViewerModule,
    jqxGridModule,
    jqxDataTableModule,
    jqxDropDownListModule,
    NgxChartsModule,
    EmtDialogComponent,
    DxFilterBuilderModule,
    GoogleMapsModule,
    SidebarModule,
    InputTextModule,
    DxSpeedDialActionModule,
    DxSelectBoxModule,
    DropdownModule,
    DialogModule,
    ButtonModule,
    //NgxTableModule,
    
    //GridModule
    
];



@NgModule({
    imports: [...imports],
    declarations: [ThemesLayoutBaseComponent, EmtAddressDisplayComponent, EmtContactDisplayComponent],
    exports: [...imports, EmtAddressDisplayComponent, EmtContactDisplayComponent, EmtDialogComponent],
})
export class AppSharedModule {
}

